import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-cont">
      {/* <div className="moon">
        <img
          src="https://cdn2.iconfinder.com/data/icons/thesquid-ink-40-free-flat-icon-pack/64/space-rocket-512.png"
          style={{ width: 30, height: 20 }}
          className="loader"
        />
        <ul>
          <li className="loader-li"></li>
          <li className="loader-li"></li>
          <li className="loader-li"></li>
          <li className="loader-li"></li>
          <li className="loader-li"></li>
          <li className="loader-li"></li>
          <li className="loader-li"></li>
        </ul>
      </div> */}
    </div>
  );
};

export default Loader;
